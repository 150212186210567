import { createSelector } from "reselect";
import { assessmentAnswerGroupsResourceSelectors } from "../assessment_answer_groups_resource_selectors";

export function createAssessmentAnswerGroupsForPatientIdSelector(patientId) {
  return createSelector(
    assessmentAnswerGroupsResourceSelectors.dataSelector,
    aags =>
      Object.values(aags).filter(
        ({ attributes }) => attributes.patientId == patientId
      )
  );
}
