import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";
import i18n from "src/translations";

import { trackError } from "src/error_tracking";

// Constants
import { resourceNames } from "src/constants";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Api
import {
  fetchStudyNonWoundAssessmentAnswers,
  fetchStudyAssessmentAnswers
} from "src/api/studies";
import {
  assessmentAnswersApi,
  assessmentAnswersLockApi,
  assessmentAnswersSignApi
} from "src/api/assessment_answers";

// Actions
import {
  assessmentAnswerGroupsResourceActions,
  usersResourceActions
} from "src/actions/data_actions";
import { createLoadDataAction } from "../create_load_data_action";
import { createPostDataAction } from "../create_post_data_action";
import { createPutDataAction } from "../create_put_data_action";
import { createDeleteDataActionWithDeleteStatusById } from "../create_delete_data_action_with_delete_status_by_id";
import { createSignAndLockAssessmentAnswerAction } from "../sign_and_lock_assessment_answer_action";

import { create } from "src/actions/flashMessageActions";

// Tracking
import { startTimeTrack, finishTimeTrack } from "src/analytics";

export const assessmentAnswersResourceActions = createResourceActions(
  "assessmentAnswers"
);

export const loadAssessmentAnswersAction = createLoadDataAction({
  resourceName: resourceNames.assessmentAnswers,
  api: assessmentAnswersApi,
  responseNormalizer: responseData => {
    let convertedData = normalize(responseData, { camelizeKeys: false });

    // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
    // /-/ => dont want to camelize uuids
    convertedData = camelizeKeys(responseData, (key, convert, options) => {
      return /-/.test(key) ? key : convert(key, options);
    });
    return convertedData;
  }
});

export const updateAssessmentAnswerQaNote = payload => dispatch => {
  dispatch(
    assessmentAnswersResourceActions.replaceAction({
      data: payload,
      contexts: []
    })
  );
};

/**
 * load the non-wound assessment answers in a study
 * @param {string} studyId
 * @returns {Function} thunk action
 */
export function loadNonWoundAssessmentAnswersForStudyAction(studyId) {
  return dispatch => {
    const context = `studies/${studyId}/assessment-answers?filter[assessment_type]=non_wound`;
    dispatch(assessmentAnswersResourceActions.loadingForContextAction(context));
    startTimeTrack("API_GET_STUDY_NON_WOUND_ASSESSMENT_ANSWERS");
    return fetchStudyNonWoundAssessmentAnswers(studyId)
      .then(response => {
        finishTimeTrack("API_GET_STUDY_NON_WOUND_ASSESSMENT_ANSWERS");

        let data = normalize(response.data, {
          camelizeKeys: false
        });

        // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
        // /-/ => dont want to camelize uuids
        data = camelizeKeys(data, (key, convert, options) => {
          return /-/.test(key) ? key : convert(key, options);
        });

        const assessmentAnswers = data.assessmentAnswers;
        const assessmentAnswerGroups = data.assessmentAnswerGroups;
        let users = data.users;

        dispatch(
          assessmentAnswerGroupsResourceActions.populateAction({
            contexts: studyId,
            data: assessmentAnswerGroups,
            ids: assessmentAnswerGroups
              ? Object.keys(assessmentAnswerGroups)
              : []
          })
        );

        if (users) {
          dispatch(
            usersResourceActions.populateAction({
              contexts: studyId,
              data: users,
              ids: Object.keys(users)
            })
          );
        }

        dispatch(
          assessmentAnswersResourceActions.populateAction({
            contexts: context,
            data: assessmentAnswers,
            ids: assessmentAnswers ? Object.keys(assessmentAnswers) : [],
            links: response.data.links,
            meta: response.data.meta
          })
        );
      })
      .catch(err => {
        dispatch(
          assessmentAnswersResourceActions.loadErrorForContextAction(context)
        );
        trackError(err);
        finishTimeTrack("API_GET_STUDY_NON_WOUND_ASSESSMENT_ANSWERS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

/**
 * Action creator to load assessment answers for study id
 * @param {string} studyId
 * @param {boolean} loadMore
 * @returns {Function} thunk action
 */
export function loadAssessmentAnswersForStudyAction(studyId, loadMore = false) {
  return dispatch => {
    startTimeTrack("API_GET_STUDY_ASSESSMENT_ANSWERS");

    if (loadMore) {
      dispatch(
        assessmentAnswersResourceActions.loadingMoreForContextAction(studyId)
      );
    } else {
      dispatch(
        assessmentAnswersResourceActions.loadingForContextAction(studyId)
      );
    }

    return fetchStudyAssessmentAnswers(studyId, loadMore)
      .then(response => {
        finishTimeTrack("API_GET_STUDY_ASSESSMENT_ANSWERS");

        let data = normalize(response.data, {
          camelizeKeys: false
        });

        // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
        // /-/ => dont want to camelize uuids
        data = camelizeKeys(data, (key, convert, options) => {
          return /-/.test(key) ? key : convert(key, options);
        });

        const assessmentAnswers = data.assessmentAnswers;
        const assessmentAnswerGroups = data.assessmentAnswerGroups;
        let users = data.users;

        if (assessmentAnswerGroups) {
          dispatch(
            assessmentAnswerGroupsResourceActions.populateAction({
              contexts: studyId,
              data: assessmentAnswerGroups,
              ids: Object.keys(assessmentAnswerGroups)
            })
          );
        }

        if (users) {
          dispatch(
            usersResourceActions.populateAction({
              contexts: studyId,
              data: users,
              ids: Object.keys(users)
            })
          );
        }

        dispatch(
          assessmentAnswersResourceActions.populateAction({
            contexts: studyId,
            data: assessmentAnswers,
            ids: assessmentAnswers ? Object.keys(assessmentAnswers) : [],
            links: response.data.links,
            meta: camelizeKeys(response.data.meta)
          })
        );
      })
      .catch(err => {
        dispatch(
          assessmentAnswersResourceActions.loadErrorForContextAction(studyId)
        );
        trackError(err);
        finishTimeTrack("API_GET_STUDY_ASSESSMENT_ANSWERS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

export const lockAssessmentAnswerAction = createPutDataAction({
  resourceName: resourceNames.assessmentAnswers,
  api: assessmentAnswersLockApi,
  onSuccess: dispatch => {
    dispatch(create("Evaluation locked successfully", "success"));
  },
  onError: (dispatch, error) => {
    let errorCode = error.response.status;
    if (![403, 409, 423].includes(errorCode)) {
      errorCode = "default";
    }
    const errorMessage = i18n.t(
      `interaction.errorMessages.woundEvaluations.lock.${errorCode}`
    );
    dispatch(create(errorMessage, "error"));
  },
  responseNormalizer: data => {
    return {
      [data.id]: {
        type: resourceNames.assessmentAnswers,
        id: data.id,
        attributes: {
          ...parseAssessmentAnswers(data)
        }
      }
    };
  }
});

export const unlockAssessmentAnswerAction = createDeleteDataActionWithDeleteStatusById(
  {
    resourceName: resourceNames.assessmentAnswers,
    api: assessmentAnswersLockApi,
    onSuccess: dispatch => {
      dispatch(create("Evaluation unlocked successfully", "success"));
    },
    onError: dispatch => {
      dispatch(
        create("Sorry, there was an error unlocking the evaluation", "error")
      );
    },
    responseNormalizer: data => {
      return {
        type: resourceNames.assessmentAnswers,
        id: data.id,
        attributes: {
          ...parseAssessmentAnswers(data)
        }
      };
    }
  }
);

export const signAssessmentAnswerAction = createPutDataAction({
  resourceName: resourceNames.assessmentAnswers,
  api: assessmentAnswersSignApi,
  onSuccess: dispatch => {
    dispatch(create("Evaluation signed successfully", "success"));
  },
  onError: (dispatch, error) => {
    let errorCode = error.response.status;
    if (![403, 409, 423].includes(errorCode)) {
      errorCode = "default";
    }
    const errorMessage = i18n.t(
      `interaction.errorMessages.woundEvaluations.sign.${errorCode}`
    );
    dispatch(create(errorMessage, "error"));
  },
  responseNormalizer: data => {
    return {
      [data.id]: {
        type: resourceNames.assessmentAnswers,
        id: data.id,
        attributes: {
          ...parseAssessmentAnswers(data)
        }
      }
    };
  }
});

export const reopenAssessmentAnswerAction = createDeleteDataActionWithDeleteStatusById(
  {
    resourceName: resourceNames.assessmentAnswers,
    api: assessmentAnswersSignApi,
    onSuccess: dispatch => {
      dispatch(create("Evaluation reopened successfully", "success"));
    },
    onError: dispatch => {
      dispatch(
        create("Sorry, there was an error reopening the evaluation", "error")
      );
    },
    responseNormalizer: data => {
      return {
        type: resourceNames.assessmentAnswers,
        id: data.id,
        attributes: {
          ...parseAssessmentAnswers(data)
        }
      };
    }
  }
);

export const signAndLockAssessmentAnswersAction = createSignAndLockAssessmentAnswerAction(
  {
    resourceName: resourceNames.assessmentAnswers,
    signApi: assessmentAnswersSignApi,
    lockApi: assessmentAnswersLockApi,
    onSuccess: dispatch => {
      dispatch(create("Evaluation signed and locked successfully", "success"));
    },
    onError: (dispatch, error, action) => {
      let errorCode = error.response.status;
      if (![403, 409, 423].includes(errorCode)) {
        errorCode = "default";
      }
      const errorMessage = i18n.t(
        `interaction.errorMessages.woundEvaluations.${action}.${errorCode}`
      );
      dispatch(create(errorMessage, "error"));
    },
    responseNormalizer: data => {
      return {
        [data.id]: {
          type: resourceNames.assessmentAnswers,
          id: data.id,
          attributes: {
            ...parseAssessmentAnswers(data)
          }
        }
      };
    }
  }
);

function parseAssessmentAnswers(data) {
  const camelizedData = camelizeKeys(data, (key, convert, options) => {
    return /-/.test(key) ? key : convert(key, options);
  });
  const parsedAA = JSON.parse(camelizedData.answersJson);
  camelizedData.answersJson = parsedAA;
  return camelizedData;
}
export const createAssessmentAnswerAction = createPostDataAction({
  resourceName: resourceNames.assessmentAnswers,
  api: assessmentAnswersApi,
  onSuccess: dispatch => {
    dispatch(create("Evaluation successfully updated", "success"));
  },
  onError: dispatch => {
    dispatch(
      create("Sorry, there was an error updating the evaluation", "error")
    );
  }
});
